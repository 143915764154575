import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }

import AvailabilityGrid from "./components/AvailabilityGrid.vue";
  import PunchHeader from "./components/PunchHeader.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PunchHeader),
    _createVNode(AvailabilityGrid)
  ]))
}
}

})