<template>
  <div class="header row">
    <div class="title center bold col">

      Punch Coachkeuzetool

    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PunchHeader',
});
</script>

<style scoped>
.header {
  background-color: #1d4e9a;
  border: 1px solid #004b63;
  margin-top: 2em;
  margin-bottom: 2em;
  color: white;
  vertical-align: middle;
}

.title {
  padding: 10px;
  font-size: 2em;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
</style>
