import {Match} from "@/model/Match";
import {Availability} from "@/model/Availability";

export class MatchHelper {
    readonly HOUR = 1000 * 60 * 60;

    matches: Match[]

    constructor(matches: Match[]) {
        this.matches = matches;
    }

    getMatchesForTeam(team: string): Match[] {
        return this.matches.filter(match => match.teamThuis === team || match.teamUit === team);
    }

    getMatchesForTeams(teams: string[]): Match[] {
        let allMatches: Match[] = []
        for (const team of teams) {
            allMatches = [...allMatches, ...this.getMatchesForTeam(team)];
        }
        return allMatches;
    }

    getAvailabilityForTeams(teams: string[], targetTeam: string): Availability {
        if (teams.length === 0 || (teams.length === 1 && teams[0] === targetTeam)) {
            return new Availability(targetTeam, targetTeam, 0, Infinity);
        }

        const targetMatches = this.getMatchesForTeam(targetTeam);

        const possibleConflictingMatches = this.getMatchesForTeams(teams);

        let available = 0;
        for (const targetMatch of targetMatches) {
            if (!this.listOfMatchesConflict(possibleConflictingMatches, targetMatch)) {
                available += 1
            }
        }

        const total = targetMatches.length;
        return new Availability(teams.join(", "), targetTeam, available, total);

    }

    /**
     * Checks the list of teams for all their matches. Returns false if at least one of the teams matches2020 conflicts.
     * This introduces the edge case where someone from teams A and B can be at the targetMatch A-B for either team A or B.
     * @param teams
     * @param targetMatch
     */
    canBeAtMatch(teams: string[], targetMatch: Match) {
        const allMatches = this.getMatchesForTeams(teams)
        return !this.listOfMatchesConflict(allMatches, targetMatch);

    }

    /**
     * Checks the list of matches. Returns false if at least one of the teams matches2020 conflicts.
     * This introduces the edge case where someone from teams A and B can be at the targetMatch A-B for either team A or B.
     * @param list
     * @param targetMatch
     */
    listOfMatchesConflict(list: Match[], targetMatch: Match) {
        for (const match of list) {
            if (this.matchesConflict(match, targetMatch)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Returns true if you cannot be at the 2 matches.
     * This method is symmetric.
     * @param one
     * @param other
     */
    matchesConflict(one: Match, other: Match): boolean {
        // You can be at the same match.
        if (Match.areEqual(one, other)) {
            return false;
        }
        // If different dates, nothing to worry about
        if (one.datum.getTime() !== other.datum.getTime()) {
            return false;
        }


        // Same date and time is a no-no
        if (this.timesCollide(one.tijd, other.tijd)) {
            return true;
        }

        // Different time but same place should be possible if you have a bike
        if (one.plaats === other.plaats) {
            return false;
        }

        // Otherwise there is a conflict
        return true;
    }

    timesCollide(time1: Date, time2: Date): boolean {
        const anHourAgo = time1.getTime() - this.HOUR;
        const anHourInTheFuture = time1.getTime() + this.HOUR;

        return time2.getTime() >= anHourAgo && time2.getTime() <= anHourInTheFuture;
    }
}
