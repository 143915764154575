export class Availability {
    from: string;
    target: string
    available: number;
    total: number;
    percentage: number;


    constructor(from: string, target: string, available: number, total: number) {
        this.from = from;
        this.target = target;

        this.available = available;
        this.total = total;
        this.percentage = this.available / this.total;
    }
}
