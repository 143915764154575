<template>
  <div class="content row">
    <div class="menu col-md-2 col-12 order-12 order-md-1 column-container">

      <p style="margin-top: 20px; min-height: 50px;">{{ selectedTeamsDisplay }}</p>

      <div class="team-menu">
        <div v-for="(team, index) in teams" :key="team" class="form-check">

          <h4 class="gender-title" v-show="index === 0">Gents</h4>
          <h4 class="gender-title" v-show="index === 14">Ladies</h4>

          <input class="form-check-input" :id="'checkbox-' + team" type="checkbox" v-model="selectedTeams"
            :value="team" />
          <label class="form-check-label" :for="'checkbox-' + team">{{ team }}</label>
        </div>
      </div>
    </div>
    <div class="information col-md-10 col-12 order-1 order-md-12 column-container">

      <div class="input-group">
        <p>Hide percentages below
          <label for="slider">{{ $filters.percentage(percentageThreshold) }}%</label>
        </p>

        <input type="range" min="0" max="1" step="0.01" class="custom-range form-control" id="slider"
          v-model="percentageThreshold">
      </div>

      <div class="table-container">
        <table class="table table-sm" @mouseleave="clearHover">
          <thead>
          <tr>
            <th></th>
            <th v-for="team of teams" :key="team">{{ $filters.removePunch(team) }}</th>
          </tr>

          </thead>
          <tbody>
            <tr style="height:30px;">
              <td style="border: none;"></td>
            </tr>

            <tr v-if="selectedTeams.length > 0">
              <th>You</th>

              <availability-cell v-for="(availability, index) of personalAvailabilities"
                :key="teams[index] + availability.available + '/' + availability.total" :availability="availability"
                :should-show="percentageThreshold <= availability.percentage" :highlighted="false">
              </availability-cell>
            </tr>
            <tr v-else>
              <th>You</th>
              <td :colspan="personalAvailabilities.length">Select teams to calculate your personal availability</td>
            </tr>

            <tr style="height:30px;">
              <td v-for="i of personalAvailabilities.length" :key="i"></td>
            </tr>


          <tr v-for="(row, y) of this.availabilityMatrix" :key="teams[y]">
            <th>{{ $filters.removePunch(teams[y]) }}</th>

              <availability-cell @mouseover="setHover(x, y)" v-for="(availability, x) of row"
                :key="teams[x] + availability.available + '/' + availability.total" :availability="availability"
                :should-show="percentageThreshold <= availability.percentage"
                :highlighted="hoveredColumn === x || hoveredRow === y">
              </availability-cell>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { MatchHelper } from '@/MatchHelper';
import { matches } from '@/MatchData';
import AvailabilityCell from '@/components/AvailabilityCell';

export default defineComponent({
  name: 'AvailabilityGrid',
  components: { AvailabilityCell },

  data: function () {
    return {
      matchHelper: new MatchHelper(matches),

      percentageThreshold: 0,

      availabilityMatrix: null,

      selectedTeams: [],

      hoveredRow: -1,
      hoveredColumn: -1,

      // I know, I just can't be bothered
      teams: [
        'Punch HS 1',
        'Punch HS 2',
        'Punch HS 3',
        'Punch HS 4',
        'Punch HS 5',
        'Punch HS 6',
        'Punch HS 7',
        'Punch HS 8',
        'Punch HS 9',
        'Punch HS 10',
        'Punch HS 11',
        'Punch HS 12',
        'Punch HS 13',
        'Punch HS 14',
        'Punch DS 1',
        'Punch DS 2',
        'Punch DS 3',
        'Punch DS 4',
        'Punch DS 5',
        'Punch DS 6',
        'Punch DS 7',
        'Punch DS 8',
        'Punch DS 9',
        'Punch DS 10',
        'Punch DS 11',
        'Punch DS 12',
        'Punch DS 13',
        'Punch DS 14',
      ]
    };
  },

  computed: {
    personalAvailabilities() {
      const list = [];
      for (const team of this.teams) {
        list.push(this.matchHelper.getAvailabilityForTeams(this.selectedTeams, team));
      }
      return list;
    },

    selectedTeamsDisplay() {
      if (this.selectedTeams.length === 0) {
        return 'Select teams to calculate your personal availability';
      }
      const strippedSelection = this.selectedTeams.map((value) => {
        return value.replace('Punch', '').replace('S ', '');
      });
      return strippedSelection.join(', ');
    }
  },

  methods: {
    setHover(x, y) {
      this.hoveredColumn = x;
      this.hoveredRow = y;
    },
    clearHover() {
      this.hoveredColumn = -1;
      this.hoveredRow = -1;

    }

  },

  mounted() {
    const grid = [];
    for (const team of this.teams) {
      const row = [];
      for (const targetTeam of this.teams) {
        row.push(this.matchHelper.getAvailabilityForTeams([team], targetTeam));
      }
      grid.push(row);
    }
    this.availabilityMatrix = grid;
  },
});
</script>

<style scoped lang="scss">
@import "../variables.scss";

.custom-range {
  margin-left: 10px;
  border-radius: 0;
}

.team-menu {}

.table {
  empty-cells: show;
}

.column-container {
  background-color: white;
  border: 0.10em solid #cecece;
}

.table-container {
  overflow: auto;
}

.gender-title {
  font-weight: bold;
  margin-top: 20px;
}

thead th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
}

tbody th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  background-color: $punchGrey;
}

thead th:first-child {
  left: 0;
  z-index: 1;
  background-color: white;
}
</style>
