import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

// Vue.config.productionTip = false

app.config.globalProperties.$filters = {
  percentage (value: number) {
    return Math.floor(value * 100);
  },
  floor (value: number) {
    return Math.floor(value);
  },
  removePunch(value: string) {
    return value.replace("Punch ", "").replace("S ", "");
  }
}

app.mount('#app');
