<template>
  <td class="table-cell" :class="{ 'highlighted': highlighted }" :title="title">
    <span v-if="availability.total === Infinity"> - </span>

    <span
        v-else-if="shouldShow" :class="colorClass">
      {{ $filters.percentage(availability.percentage) }}%
    </span>
  </td>
</template>

<script>
import { defineComponent } from 'vue';

import { Availability } from '@/model/Availability';

export default defineComponent({
  name: 'AvailabilityCell',

  props: {
    availability: {
      required: true,
      type: Availability
    },
    shouldShow: {
      required: true,
      type: Boolean,
    },
    highlighted: {
      required: true,
      type: Boolean,
    }
  },

  computed: {
    colorClass() {
      if (this.availability.percentage <= 0.4) {
        return 'low-percentage';
      }
      if (this.availability.percentage >= 0.8) {
        return 'high-percentage';
      }
      return 'medium-percentage';
    },
    title() {
      if (this.availability.total === Infinity) {
        return '';
      }
      const pluralS = this.availability.from.includes(',') ? 's' : '';
      return `Players from team${pluralS} ${this.availability.from} can be at ${this.availability.available}/${this.availability.total} matches of ${this.availability.target}`;
    }
  },
});
</script>

<style scoped lang="scss">
@import "../variables.scss";

.table-cell {
  padding: 0.1rem;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #dee2e6;
  ;
}

tr {
  text-align: center;
}

.highlighted {
  background-color: $punchSelection;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
}

.low-percentage {
  color: red;
}

.medium-percentage {
  color: blue;
}

.high-percentage {
  color: green;
}
</style>
