export class Match {
    datum: Date;
    tijd: Date;
    plaats: string;
    teamThuis: string;
    teamUit: string;


    constructor(datum: Date, tijd: Date, plaats: string, teamThuis: string, teamUit: string) {
        this.datum = datum;
        this.tijd = tijd;
        this.plaats = plaats;
        this.teamThuis = teamThuis;
        this.teamUit = teamUit;
    }

    static areEqual(one: Match, other: Match) {
        return one.datum === other.datum &&
            one.tijd === other.tijd &&
            one.plaats === other.plaats &&
            one.teamThuis === other.teamThuis &&
            one.teamUit === one.teamUit;
    }
}
